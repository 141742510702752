import React, { useEffect } from 'react';
import Divider from '@material-ui/core/Divider';
import { getUrlParams } from '../helpers/location';
import MainLayout from '../components/MainLayout';
import QuestionnaireList from '../components/QuestionnaireList';
import BlogList from '../components/BlogList';
import * as Api from '../apis';

const BlogListPage = ({ location }) => {
  const query = getUrlParams(location.search);
  const category_id = query.category_id || '';
  const user_question_id = query.user_question_id || '';

  useEffect(() => {
    Api.postCountData('blogs', user_question_id);
  }, []);

  return (
    <MainLayout>
      <BlogList categoryId={category_id} />
      <Divider />
      <QuestionnaireList categoryId={category_id} />
    </MainLayout>
  );
};

export default BlogListPage;
